import { Controller } from "stimulus";

import * as filestack from "filestack-js";

export default class extends Controller {
  static values = {
    memoryId: Number,
    pickerType: String,
    s3Region: String,
    s3Container: String,
    filestackApiKey: String,
    filestackPolicy: String,
    filestackSignature: String,
    isSequence: Boolean,
    convertToSequence: Boolean,
  };
  static targets = ["uploadButton"];

  openPicker() {
    const client = filestack.init(this.filestackApiKeyValue, {
      security: {
        policy: this.filestackPolicyValue,
        signature: this.filestackSignatureValue,
      },
    });
    const options = Object.assign(
      this.defaultPickerOptions(),
      this.pickerOptionsByType(this.pickerTypeValue)
    );

    console.debug(options);

    client.picker(options).open();
  }

  pickerOptionsByType(type) {
    switch (type.toLowerCase()) {
      case "image":
        return {
          accept: ["image/*"],
        };
        break;
      case "video":
        return {
          maxFiles: 1,
          accept: ["video/*", ".mov", ".wmv", ".flv"],
          maxSize: 1024 * 1024 * 1024, // 1 gigabyte
        };
        break;
      case "powerpoint":
        return {
          maxFiles: 1,
          accept: [".ppt", ".pptx"],
          maxSize: 1024 * 1024 * 1024, // 1 gigabyte
          fromSources: ["local_file_system"],
        };
        break;
      case "audio":
        return {
          maxFiles: 1,
          maxSize: 50 * 1024 * 1024, // 50 megabytes
          accept: ["audio/*", ".aac", ".wma"],
          fromSources: ["local_file_system"],
        };
        break;
      default:
        throw `Unknown picker type: ${type}`;
    }
  }

  defaultPickerOptions() {
    return {
      maxFiles: 100,
      maxSize: 20 * 1024 * 1024, // 20 megabytes
      fromSources: ["local_file_system", "facebook", "instagram"],
      imageMax: [1920, 1080],
      cleanupImageExif: {
        keepOrientation: true,
        keepICCandAPP: true,
      },
      storeTo: {
        location: "s3",
        path: "/",
        access: "private",
        region: this.s3RegionValue,
        container: this.s3ContainerValue,
      },
      videoResolution: "1280x720",
      viewType: "grid",
      onUploadDone: this.handleFinishedUpload.bind(this),
    };
  }

  handleFinishedUpload(data) {
    data.filesFailed.forEach((failed) => {
      console.error("Failed to upload file:");
      console.error(failed);
    });

    if (this.convertToSequenceValue)
      this.convertMemoryToSequence(this.memoryIdValue);

    if (
      this.pickerTypeValue.toLowerCase() === "image" &&
      (data.filesUploaded.length > 1 ||
        this.convertToSequenceValue ||
        this.isSequenceValue)
    ) {
      // Sequence
      this.batchCreateSequenceImages(data.filesUploaded, this.memoryIdValue);
    } else {
      // Non-sequence
      const file = data.filesUploaded[0];

      switch (this.pickerTypeValue.toLowerCase()) {
        case "image":
        case "video":
        case "powerpoint":
          this.attachAssetToMemory(file, this.memoryIdValue);
          break;
        case "audio":
          this.createAudioClip(file, this.memoryIdValue);
          break;
        default:
          throw `Unknown picker type: ${type}`;
      }
    }
  }

  createAudioClip(file, memoryId) {
    const csrfToken = document
      .querySelector("meta[name=csrf-token]")
      .getAttribute("content");

    fetch("audio_clips.json", {
      method: "POST",
      mode: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        audio_clip: {
          file: file,
          memory_id: memoryId,
        },
      }),
    }).then((response) => {
      if (response.ok) {
        location.reload(true);
      } else {
        console.error(Error(data.err || "HTTP error"));
      }
    });
  }

  attachAssetToMemory(file, memoryId) {
    const csrfToken = document
      .querySelector("meta[name=csrf-token]")
      .getAttribute("content");

    fetch(`memories/${memoryId}.json`, {
      method: "PATCH",
      mode: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        filestack_url: file.url,
        file_name: file.filename,
        file_type: file.mimetype,
        file_source: file.source,
        file_size: file.size,
      }),
    }).then((response) => {
      if (response.ok) {
        location.href = response.redirect_url;
        location.reload(true);
      } else {
        console.error(Error(data.err || "HTTP error"));
      }
    });
  }

  convertMemoryToSequence(memoryId) {
    const csrfToken = document
      .querySelector("meta[name=csrf-token]")
      .getAttribute("content");

    fetch(`memories/${memoryId}/image_to_sequence.json`, {
      method: "POST",
      mode: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": csrfToken,
      },
    }).then((response) => {
      if (!response.ok) throw Error(data.err || "HTTP error");
    });
  }

  batchCreateSequenceImages(files, memoryId) {
    const csrfToken = document
      .querySelector("meta[name=csrf-token]")
      .getAttribute("content");

    fetch(`sequence_images/batch_create.json`, {
      method: "POST",
      mode: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        files: files,
        memory_id: memoryId,
      }),
    }).then((response) => {
      if (response.ok) {
        location.href = response.redirect_url;
        location.reload(true);
      } else {
        console.error(Error(data.err || "HTTP error"));
      }
    });
  }
}
