import { Controller } from "stimulus";

import "../packs/i18n-language";

export default class extends Controller {
  static targets = ["previewLink"];

  connect() {
    this.previewLinkTarget.innerText = this.buttonText();

    // document.getElementById("filterrific_filter").addEventListener("loadedFilterrificResults", )
  }

  async enablePreview(event) {
    event.preventDefault();

    const fileUrl = await this.getFileUrl();

    console.debug(fileUrl);

    let playerDiv = document.createElement("div");
    playerDiv.setAttribute("data-controller", "memories--media-controls");
    playerDiv.innerHTML = `
      <audio src="${fileUrl}"
             autoplay
             controls
             style="display: none"
             data-action="
               durationchange->memories--media-controls#updateTotalDuration
               timeupdate->memories--media-controls#updateProgress
             "
             data-memories--media-controls-target="controls"></audio>
      <div id="media-controls">
        <button
          id="play-pause"
          class="button-link"
          data-action="memories--media-controls#togglePlayPause"
          data-memories--media-controls-target="playPauseButton">
          <i class="fa fa-play"></i>
        </button>

        <div id="progress-bar">
          <input
            type="range"
            id="progress"
            value="0"
            data-memories--media-controls-target="progressBarInput"
            data-action="change->memories--media-controls#setProgress"
          >
        </div>

        <div id="timestamp" data-memories--media-controls-target="timestampDiv">
          -:--
        </div>
      </div>

    `;

    this.previewLinkTarget.remove();
    this.element.appendChild(playerDiv);
  }

  async getFileUrl() {
    const csrfToken = document
      .querySelector("meta[name=csrf-token]")
      .getAttribute("content");

    return fetch(`/dropbox/file_url.json?id=${this.musicId()}`, {
      method: "POST",
      mode: "same-origin",
      headers: {
        Accept: "text/javascript",
        ContentType: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": csrfToken,
      },
    })
      .then((response) => response.json())
      .then((data) => data.link);
  }

  musicId() {
    const id = this.element.getAttribute("data-music-id");

    if (!id) throw "No ID data attribute for music file";

    return id;
  }

  buttonText() {
    return I18n.t("models.music_file.preview");
  }
}
