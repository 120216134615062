import { Controller } from "stimulus";

import Sortable from "sortablejs";

export default class extends Controller {
  static targets = [];

  connect() {
    Sortable.create(this.element, {
      ghostClass: "sortable_ghost",
      chosenClass: "sortable_chosen",
      dragClass: "sortable_drag",
      handle: ".card-action--move",
      scrollSensitivity: 50,
      scrollSpeed: 5,
      forceFallback: true,
      fallbackTolerance: 5,
      animation: 150,
      onStart: (event) => {
        event.item
          .querySelector(".card-wrapper[numberable]")
          .classList.remove("card--numbered");
      },
      onEnd: (event) => {
        event.item
          .querySelector(".card-wrapper[numberable]")
          .classList.add("card--numbered");
      },
      onUpdate: (event) => {
        const csrfToken = document
          .querySelector("meta[name=csrf-token]")
          .getAttribute("content");
        const post_object = this.element.getAttribute("data-sortable-type");

        Array.from(this.element.children)
          .filter((element) => element.tagName.toLowerCase() === "li")
          .forEach((element, index) => {
            const id = element.getAttribute("data-id");

            if (id) {
              element.setAttribute("data-sort-index", index + 1);

              fetch(`${post_object}/${id}.json`, {
                method: "PATCH",
                mode: "same-origin",
                headers: {
                  "Content-Type": "application/json",
                  "X-Requested-With": "XMLHttpRequest",
                  "X-CSRF-Token": csrfToken,
                },
                body: JSON.stringify({
                  sort_index: index + 1,
                }),
              });
            }
          });
      },
    });
  }
}
