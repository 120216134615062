import { Controller } from "stimulus";

require("pickadate/lib/picker");
require("pickadate/lib/picker.date");
require("pickadate/lib/picker.time");
import "pickadate/lib/compressed/themes/default.css";
import "pickadate/lib/compressed/themes/default.date.css";
import "pickadate/lib/compressed/themes/default.time.css";

export default class extends Controller {
  static targets = [
    "wrapper",
    "ceremonyStartDateField",
    "ceremonyStartTimeField",
    "ceremonyEndTimeField",
  ];

  connect() {
    if (this.locale() === "nl") this.useDutch();

    jQuery.extend(jQuery.fn.pickatime.defaults, {
      format: "H:i",
    });

    var dateFormatSubmit = "yyyy/mm/dd";
    var timeFormatSubmit = "HH:i";

    if (this.hasCeremonyStartDateFieldTarget) {
      jQuery(this.ceremonyStartDateFieldTarget).pickadate({
        formatSubmit: dateFormatSubmit,
        hiddenName: true,
      });
    }

    if (this.hasCeremonyStartTimeFieldTarget) {
      jQuery(this.ceremonyStartTimeFieldTarget).pickatime({
        formatSubmit: timeFormatSubmit,
        hiddenName: true,
        interval: 5,
      });
    }

    if (this.hasCeremonyEndTimeFieldTarget) {
      jQuery(this.ceremonyEndTimeFieldTarget).pickatime({
        formatSubmit: timeFormatSubmit,
        hiddenName: true,
        interval: 5,
      });
    }
  }

  locale() {
    return this.wrapperTarget.getAttribute("data-locale").toLowerCase();
  }

  useDutch() {
    jQuery.extend(window.jQuery.fn.pickadate.defaults, {
      monthsFull: [
        "januari",
        "februari",
        "maart",
        "april",
        "mei",
        "juni",
        "juli",
        "augustus",
        "september",
        "oktober",
        "november",
        "december",
      ],
      monthsShort: [
        "jan",
        "feb",
        "mrt",
        "apr",
        "mei",
        "jun",
        "jul",
        "aug",
        "sep",
        "okt",
        "nov",
        "dec",
      ],
      weekdaysFull: [
        "zondag",
        "maandag",
        "dinsdag",
        "woensdag",
        "donderdag",
        "vrijdag",
        "zaterdag",
      ],
      weekdaysShort: ["zo", "ma", "di", "wo", "do", "vr", "za"],
      today: "vandaag",
      clear: "wissen",
      close: "sluiten",
      firstDay: 1,
      format: "dddd d mmmm yyyy",
      formatSubmit: "yyyy/mm/dd",
    });

    jQuery.extend(jQuery.fn.pickatime.defaults, {
      clear: "wissen",
      format: "H:i",
    });
  }
}
