import { Controller } from "stimulus";

import "slick-carousel";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

export default class extends Controller {
  static targets = ["container", "imagesDiv", "imageDiv", "dotsDiv"];

  connect() {
    if (this.hasImagesDivTarget) this.slickSetup();
  }

  slickSetup() {
    if (this.imagesDivTarget.classList.contains("slick-initialized")) return;

    jQuery(this.imagesDivTarget).slick({
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      fade: true,
      cssEase: "linear",
      appendDots: this.dotsDivTarget,
    });
  }

  nextImage() {
    jQuery(this.imagesDivTarget).slick("slickNext");
  }

  previousImage() {
    jQuery(this.imagesDivTarget).slick("slickPrev");
  }
}
