import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.editableElements().forEach((element) => {
      jQuery(element).best_in_place();
    });
  }

  editableElements() {
    return Array.from(this.element.getElementsByClassName("best_in_place"));
  }
}
