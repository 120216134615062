import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "controls",
    "playPauseButton",
    "progressBarInput",
    "timestampDiv",
  ];

  togglePlayPause() {
    if (this.controlsTarget.paused || this.controlsTarget.ended) {
      this.playPauseButtonTarget.innerHTML = '<i class="fa fa-pause">';
      this.controlsTarget.play();
    } else {
      this.playPauseButtonTarget.innerHTML = '<i class="fa fa-play">';
      this.controlsTarget.pause();
    }
  }

  updateProgress() {
    if (this.controlsTarget.currentTime > 0) {
      this.progressBarInputTarget.value = Math.floor(
        (100 / this.controlsTarget.duration) * this.controlsTarget.currentTime
      );
    }
  }

  setProgress() {
    this.controlsTarget.currentTime =
      this.controlsTarget.duration * (this.progressBarInputTarget.value / 100);
  }

  updateTotalDuration(event) {
    const duration = Math.round(this.controlsTarget.duration);
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    const secondsString = `0${seconds}`.slice(-2);
    const durationString = `${minutes}:${secondsString}`;

    this.timestampDivTarget.innerHTML = durationString;
  }
}
