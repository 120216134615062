import { Controller } from "stimulus";

import "../../packs/i18n-language";

import introJs from "intro.js";

import "intro.js/minified/introjs.min.css";
import "intro.js/themes/introjs-nassim.css";

export default class extends Controller {
  connect() {
    if (this.shouldTriggerIntro()) this.intro().start();
  }

  validMusicServer() {
    const attr = this.element.getAttribute("data-valid-music-server");

    if (!attr)
      throw "Missing data-valid-music-server attribute on controller element.";

    return attr.toLowerCase() == "true";
  }

  shouldTriggerIntro() {
    const attr = this.element.getAttribute("data-should-intro");

    if (!attr)
      throw "Missing data-should-intro attribute on controller element.";

    return attr.toLowerCase() == "true";
  }

  intro() {
    const steps = [
      {
        element: ".card-fields",
        intro: I18n.t("intro.title_and_description"),
      },
      {
        element: ".card-info--empty",
        intro: I18n.t("intro.add_content"),
      },
      {
        element: ".card-info--empty_item_image",
        intro: I18n.t("intro.add_content_image"),
      },
      {
        element: ".card-info--empty_item_video",
        intro: I18n.t("intro.add_content_video"),
      },
      {
        element: ".card-info--empty_item_powerpoint",
        intro: I18n.t("intro.add_content_powerpoint"),
      },
      {
        element: ".card-action--move",
        intro: I18n.t("intro.card_move"),
      },
      {
        element: ".card-action--duplicate",
        intro: I18n.t("intro.card_duplicate"),
      },
      {
        element: ".card-action--delete",
        intro: I18n.t("intro.card_delete"),
      },
      {
        element: ".card-placeholder",
        intro: I18n.t("intro.empty_card"),
      },
    ];

    if (this.validMusicServer()) {
      steps.push({
        element: ".card-audio",
        intro: `${I18n.t("intro.music_upload")} ${I18n.t(
          "intro.music_library"
        )}`,
      });
    } else {
      steps.push({
        element: ".card-audio",
        intro: I18n.t("intro.music_upload"),
      });
    }

    return introJs().setOptions({
      steps: steps,
      showStepNumbers: false,
      nextLabel: I18n.t("intro.next"),
      prevLabel: I18n.t("intro.previous"),
      doneLabel: I18n.t("intro.done"),
    });
  }
}
